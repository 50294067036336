import React from "react";
import "../css/Resume.css";
import { Grid, Typography, Icon, Paper, TextField } from "@material-ui/core";
import resumeData from "../utils/resumeData.js";
import CustomTimeline from "./Timeline.jsx";
import { CustomTimelineSeparator } from "./Timeline";
import WorkIcon from "@material-ui/icons/Work";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import SchoolIcon from "@material-ui/icons/School";
import "../App.css";
import CustomButton from "./Button";

function Resume() {
  return (
    <div>
      {/* ABOUT ME */}

      <Grid container className="section pb_45 pt_45">
        <Grid item className="section_title mb_30">
          <span></span>
          <h6 className="section_title_text"> About me</h6>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className="aboutme_text">
            {" "}
            {resumeData.about}
          </Typography>
        </Grid>
      </Grid>

      {/* EXPERIENCE AND EDUCATION */}
      <Grid container className="section pb_45">
        <Grid item className="section_title mb_30">
          <span></span>
          <h6 className="section_title_text"> Resume </h6>
        </Grid>

        <Grid item xs={12}>
          <Grid container className="resume_timeline">
            {/* WORK EXPERIENCE */}
            <Grid item sm={12} md={6}>
              <CustomTimeline title="Work Experience" icon={<WorkIcon />}>
                {" "}
                {resumeData.experiences.map((x) => {
                  return (
                    <TimelineItem>
                      <CustomTimelineSeparator />
                      <TimelineContent className="timeline_content">
                        <Typography className="timeline_title">
                          {x.title}
                        </Typography>
                        <Typography variant="caption" className="timeline_date">
                          {x.date}
                        </Typography>
                        <Typography
                          variant="body2"
                          className="timeline_description"
                        >
                          {x.description}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}{" "}
              </CustomTimeline>
            </Grid>

            {/* EDUCATION */}
            <Grid item sm={12} md={6}>
              <CustomTimeline title="Education" icon={<SchoolIcon />}>
                {" "}
                {resumeData.education.map((x) => {
                  return (
                    <TimelineItem>
                      <CustomTimelineSeparator />
                      <TimelineContent className="timeline_content">
                        <Typography className="timeline_title">
                          {x.title}
                        </Typography>
                        <Typography variant="caption" className="timeline_date">
                          {x.date}
                        </Typography>
                        <Typography
                          variant="body2"
                          className="timeline_description"
                        >
                          {x.description}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}{" "}
              </CustomTimeline>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* SERVICES */}
      <Grid container className="section pb_45">
        <Grid item className="section_title mb_30">
          <span></span>
          <h6 className="section_title_text"> My Services </h6>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3} justify="space-around">
            {resumeData.services.map((x) => (
              <Grid item xs={12} sm={6} md={3}>
                <div className="service">
                  <Icon className="service_icon">{x.icon}</Icon>
                  <Typography className="service_title" variant="h6">
                    {" "}
                    {x.title}{" "}
                  </Typography>
                  <Typography className="service_description" variant="body2">
                    {" "}
                    {x.description}{" "}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {/* SKILLS */}
      <Grid container className="section graybg pb_45 p_50">
        <Grid item xs={12}>
          <Grid container justify="space-around" spacing={3}>
            {resumeData.skills.map((x) => (
              <Grid item xs={12} md={3} sm={6}>
                <Paper elevation={0} className="skill">
                  <Typography variant="h6" className="skill_title">
                    {x.title}
                  </Typography>
                  {x.description.map((xx) => (
                    <Typography variant="body2" className="skill_description">
                      <TimelineDot
                        variant="outlined"
                        className="timeline_dot"
                      />
                      {xx}
                    </Typography>
                  ))}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {/* CONTACT */}
      <Grid container spacing={6} className="section pt_45 pb_45">
        {/* CONTACT FORM */}
        <Grid item xs={12} lg={7}>
          <Grid container>
            <Grid item className="section_title mb_30">
              <span></span>
              <h6 className="section_title_text"> Contact Form</h6>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth name="name" label="Name" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth name="email" label="E-mail" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="message"
                    label="Message"
                    multiline
                    rows={4}
                  />
                </Grid>

                <Grid item xs={12}>
                  <CustomButton text="Submit" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* CONTACT INFORMATION */}
        <Grid item xs={12} lg={5}>
          <Grid item className="section_title mb_30">
            <span></span>
            <h6 className="section_title_text"> Contact Information</h6>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className="contactInfo_item">
                  <span> Address: </span> {resumeData.address}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography className="contactInfo_item">
                  <span> Phone: </span> {resumeData.phone}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography className="contactInfo_item">
                  <span> E-mail: </span> {resumeData.email}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container className="contactInfo_socialsContainer">
              {Object.keys(resumeData.socials).map((x) => (
                <Grid item className="contactInfo_socail">
                  <a href={resumeData.socials[x].link}>
                    {" "}
                    {resumeData.socials[x].icon}{" "}
                  </a>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Resume;
