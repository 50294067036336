import { Typography } from "@material-ui/core"
import React from "react"
import '../css/Footer.css'
import resumeData from "../utils/resumeData"

function Footer()
{
    return( <div className="footer">
        <div className="footer-left">
        <Typography className="footer_name">
            {resumeData.name}
         </Typography>
          </div>
        <div className="footer-right">
        <Typography className="footer_copyright">
            Designed And Developed By <a href="/" target="_blank"> Khushal Pujara </a> 
         </Typography>
         </div>
            
    </div>);
}

export default Footer;