import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import WebIcon from "@material-ui/icons/Web";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import StorageIcon from '@material-ui/icons/Storage';

const data = {
  name: "Khushal Pujara",
  title: "Full Stack Developer",
  birthday: "1st January 1998",
  email: "khushalpujara@gmail.com",
  address: "220 John Street, Rochester",
  phone: "224 421 3697",

  socials: {
    Github: {
      link: "https://github.com/khushal1198",
      text: "MyGithub",
      icon: <GitHubIcon />,
    },
    LinkedIn: {
      link: "https://www.linkedin.com/in/khushal1998/",
      text: "MyLinkedIn",
      icon: <LinkedInIcon />,
    },
    Instagram: {
      link: "https://www.instagram.com/khushal1998/",
      text: "MyInstagram",
      icon: <InstagramIcon />,
    },
    Twitter: {
      link: "https://twitter.com/khushal1998",
      text: "MyTwitter",
      icon: <TwitterIcon />,
    },
    Facebook: {
      link: "https://www.facebook.com/khushal1998",
      text: "MyFacebook",
      icon: <FacebookIcon />,
    },
  },

  about:
    "An enthusiastic graduate student with experience in Full-Stack developement, Database Management, Machine Learning and Computer Vision looking for roles like Software/ Full-Stack Developer where I can not only utilize my knowledge but always keep on learning. \n\n I spend my free time doing competitive coding, travelling and cooking food of different cuisine. \n\n Currently looking for Summer 2021 Internship and Full-time Employment from December 2021.",

  experiences: [
    {
      title: "Data Structure & Algorithm - Teaching Assistant \n S30 Code School(California , USA)",
      date: "Auguest 2020 - Current",
      description: "Currently working as a part time Data Structures & Algorithms teaching assistant \n Mentoring over 70+ students in DSA",
    },
    {
      title: "That Indian Coder \n YouTube Channel (Online) – Coding Content Creator",
      date: "December 2020 - Current",
      description: "Create solutions and coding techniques videos for various competitive coding and Leetcode problems viewed by 100+ regular viewers."
    },
    {
      title: "SDE Intern \n Theta Technolabs(Ahmedabad, India)",
      date: "December 2018 - April 2019",
      description: "Contributed on developing a Pharmacy Management web application currently used over 100 users.",
    },
  ],

  education: [
    {
      title: "Rochester Institute Of Technology \n Rochester, USA",
      date: "Auguest 2019 - December 2021",
      description: "Degree - Master's In Computer Science \n Masters GPA - 3.86/4 ",
    },
    {
      title: "Dharmsinh Desai University \n Nadiad, Gujarat",
      date: "August 2015 - May 2019",
      description: "Degree - Bachelor's In Computer Engineering \n  CGPA - 7.2/10 ",
    },
    {
      title: "Sheth CN Vidhyalaya \n Ahmedabad, Gujarat",
      date: "2004 - 2015",
      description: "Grade 1 to Grade 12",
    },
  ],

  services: [
    {
      title: "Web Dev",
      description: "",
      icon: <WebIcon />,
    },
    {
      title: "Machine Learning",
      description: "",
      icon: <BlurOnIcon />,
    },
    {
      title: "Database Management",
      description: "",
      icon: <StorageIcon />,
    },
  ],

  skills: [
    {
      title: "Front-End",
      description: ["ReactJS", "JavaScript", "BootStrap", "Material-UI"],
    },
    {
      title: "Back-End",
      description: ["NodeJS", "Java", "Python"],
    },
    {
      title: "Database",
      description: ["MySQL", "Postgres", "MongoDB"],
    },
    {
      title: "Source Control",
      description: ["Git", "GitHub"],
    },
  ],
};

export default data;
