import { Typography } from "@material-ui/core"
import React from "react"
import "../css/Profile.css"
import image from '../assets/images/photo-removebg-preview (1).png';
import CustomTimeline from "./Timeline.jsx"
import resumeData from '../utils/resumeData'
import TimelineItem from "@material-ui/lab/TimelineItem";
import {CustomTimelineSeparator} from "./Timeline"
import TimelineContent from "@material-ui/lab/TimelineContent";
import PersonIcon from '@material-ui/icons/Person';
import CustomButton from './Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import MyPDF from '../myResume/Khushal_Pujara_Resume.pdf';

function CustomTimelineItem(item)
{
    const {title, text, link} = item
    return(<TimelineItem>
        <CustomTimelineSeparator/>
        <TimelineContent className="timeline_content">
            {link ? (
                <Typography className="timelineItem_text">
                    <span>{title}:</span>{" "} <a href={link} target='_black'>{text}</a>
                </Typography>
            ) : (
                <Typography className="timelineItem_text">
                    <span> {title}:</span> {text}
                </Typography>
            )}

        </TimelineContent>
    </TimelineItem> );
}

function Profile()
{
    return( <div className="profile container_shadow">
        <div className = "profile_name">
            <Typography className="name">
                {resumeData.name}
            </Typography>
            <Typography className="title">
            {resumeData.title}
            </Typography>

            <figure className="profile_image">
                <img src = {image} className="image" alt="" />

            </figure>

            <div className = "profile_information">
                <CustomTimeline icon= {<PersonIcon />} >
                    <CustomTimelineItem title="Name" text={resumeData.name } />
                    <CustomTimelineItem title="Title" text={resumeData.title} />
                    <CustomTimelineItem title="Email" text={resumeData.email} />

                    {Object.keys(resumeData.socials).map((x)=>{
                        return (<CustomTimelineItem title={x} text={resumeData.socials[x].text} link = {resumeData.socials[x].link} />)
                    })}

                </CustomTimeline>
                <div className='btn_container'>
                <a href ={MyPDF} download="Khushal_Pujara_Resume.pdf">
                    <CustomButton text="Download Resume" icon ={<GetAppIcon />}>
                    </CustomButton>
                    </a>
                </div>
                
            </div>

        </div>
    </div>);
}

export default Profile;