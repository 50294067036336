import React from "react"
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import "../css/Timeline.css"
import { Typography } from "@material-ui/core";
import WorkIcon from '@material-ui/icons/Work';
import resumeData from "../utils/resumeData"

function CustomTimeline(props)
{
    return(<div>
    <Timeline className="timeline">
        {/* Item1 */}
      <TimelineItem className="timeline_firstItem">
        <TimelineSeparator>
          <TimelineDot className="timeline_dot_header"> {props.icon} </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent> <Typography variant="h6" className="timeline_header" > {props.title}  </Typography></TimelineContent>
      </TimelineItem>

    
        {props.children}

      {/* Item2 */}
      {/* <TimelineItem>
        <CustomTimelineSeparator />
        <TimelineContent>Code</TimelineContent>
      </TimelineItem> */}
      {/* Item3 */}
      {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent>Sleep</TimelineContent>
      </TimelineItem> */}
    </Timeline>

    </div>);
}


function CustomTimelineSeparator()
{
    return (<TimelineSeparator className={"separator_padding"}>
    <TimelineDot variant={'outlined'} className={"timeline_dot"}/>
    <TimelineConnector />
  </TimelineSeparator>);
}

export {CustomTimelineSeparator};

export default CustomTimeline;