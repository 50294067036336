import React from "react"
import {
	Button,
	Form,
	FormControl,
	Nav,
	Navbar,
	NavDropdown
} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link, NavLink, withRouter} from "react-router-dom";
import {
	HomeRounded,
	SchoolRounded,
	WorkRounded,
	Facebook,
	Twitter,
	LinkedIn,
	GitHub,
	Telegram
} from "@material-ui/icons";
import resumeData from "../utils/resumeData"
import CustomButton from "./Button"
import "../css/Header.css"

function Header(props)
{

    const pathName = props?.location?.pathname;

    return( <Navbar expand="lg" sticky="top" className = "header">
        <Nav.Link as={NavLink} to="/" className="header_navlink" > 
            <Navbar.Brand className="header_home" >
                <HomeRounded />
            </Navbar.Brand>
        </Nav.Link>
        <Navbar.Toggle />

        <Navbar.Collapse>
            <Nav className="header_left"> 
                {/* RESUME LINK */}
                <Nav.Link as={NavLink} to="/" className={pathName == '/' ? 'header_link_active' : 'header_link'}>
                    Resume
                </Nav.Link>

                {/* PORTFOLIO LINK */}
                <Nav.Link as={NavLink} to="/portfolio" className={pathName == '/portfolio' ? 'header_link_active' : 'header_link'}>
                    Portfolio
                </Nav.Link>
            </Nav>


            <div className="header_right">
                {Object.keys(resumeData.socials).map(x => (
                    <a href={resumeData.socials[x].link} target="_blank"> {resumeData.socials[x].icon} </a>
                )   
                )}
            </div>
            <div className="header_right">
            <CustomButton text="Hire Me" icon={<Telegram/>} />
            </div>

        </Navbar.Collapse>


    </Navbar>);
}

export default withRouter(Header);