import React from "react"
import {Button} from "@material-ui/core"
import "../css/Button.css";

function CustomButton(props)
{

    return (<div>
        <Button className="custom_btn" endIcon ={props.icon? <div className="btn_icon_container"> {props.icon} </div> : null} >
            <span className = "btn_text"> {props.text} </span>
        </Button>
    </div>);
}

export default CustomButton;